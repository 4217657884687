import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Page from '../../components/desktop/Page';
import CodeLine from '../../components/common/CodeLine';
import './Team.scss';
import TextArrowButton from '../../components/common/TextArrowButton';
import teamMembers, { TeamMember } from '../common/TeamMembers';
import TeamMemberDetail from '../common/TeamMemberDetail';
import { TeamsBackground } from '../../components/desktop/Backgrounds';
import { usePage } from '../../app/common/PageContextProvider';

const background = (
  <>
    <TeamsBackground />
    <CodeLine
      color="codeline-grey-darkened"
      top={-153}
      right={0}
      width={526}
      height={401}
      leftRound
    />
    <CodeLine
      color="codeline-grey"
      right={140}
      width={217}
      bottom={75}
      leftRound
      rightRound
    />
    <CodeLine
      color="codeline-grey"
      right={393}
      bottom={75}
      leftRound
      rightRound
    />
  </>
);

type TeamMemberOverviewProps = {
  member: TeamMember,
  onShowDetail: (memberToShow: TeamMember) => void
};

function TeamMemberOverview(props: TeamMemberOverviewProps) {
  const {
    member,
    onShowDetail,
  } = props;
  const {
    photoUrl, fullName, shortName, textGroupI18nKey,
  } = member;
  const { t } = useTranslation();
  return (
    <article className="TeamMemberOverview">
      <img src={photoUrl} alt={`${fullName} photo`} className="TeamMemberOverview-photo" />
      <h2 className="TeamMemberOverview-name">{fullName}</h2>
      <p className="TeamMemberOverview-teaser"><Trans i18nKey={`team.${textGroupI18nKey}.teaser`} /></p>
      <TextArrowButton
        onClick={() => onShowDetail(member)}
        arrowPosition="right"
        text={t('team.showDetail', { name: shortName })}
      />
    </article>
  );
}

type TeamMembersProps = {
  memberInDetail: TeamMember | null,
  setMemberInDetail: (member: TeamMember | null) => void,
};

function TeamMembers(props: TeamMembersProps) {
  const { memberInDetail, setMemberInDetail } = props;
  let view;
  if (memberInDetail === null) {
    view = teamMembers.map((member) => (
      <TeamMemberOverview
        key={member.shortName}
        member={member}
        onShowDetail={setMemberInDetail}
      />
    ));
  } else {
    view = (
      <TeamMemberDetail
        member={memberInDetail}
        onHideDetail={() => setMemberInDetail(null)}
      />
    );
  }
  return (
    <div className="TeamMembers">
      {view}
    </div>
  );
}

export default function Team() {
  const [memberInDetail, setMemberInDetail] = useState<TeamMember | null>(null);
  const { currentPageIndex } = usePage();

  useEffect(() => {
    setMemberInDetail(null);
  }, [currentPageIndex]);

  return (
    <Page index={4} background={background}>
      <div className="Team">
        {memberInDetail === null && (
          <div className="Team-title">
            <h1>
              <Trans i18nKey="team.title" />
            </h1>
          </div>
        )}
        <TeamMembers
          memberInDetail={memberInDetail}
          setMemberInDetail={setMemberInDetail}
        />
      </div>
    </Page>
  );
}
