import React, { CSSProperties } from 'react';
import useIsMobile from './UseIsMobile';

type Props = {
  color: string;
  leftRound?: boolean;
  rightRound?: boolean;
  top?: number;
  bottom?: number;
  right?: number | string;
  left?: number | string;
  opacity?: number;
  height?: number;
  width?: number;
  className?: string;
};

export default function CodeLine(props: Props) {
  const {
    color,
    leftRound,
    rightRound,
    top,
    bottom,
    right,
    left,
    opacity,
    height,
    width,
    className,
  } = props;
  const isMobile = useIsMobile();
  const defaultHeight = isMobile ? 36 : 72;
  const heightToUse = height ?? defaultHeight;

  let widthToUse = undefined;
  if (width !== undefined) {
    widthToUse = width;
  } else if (right === undefined || left === undefined) {
    // noinspection JSSuspiciousNameCombination
    widthToUse = heightToUse;
  }

  const cssProperties: CSSProperties = {
    position: 'absolute',
    width: widthToUse,
    height: heightToUse,
    backgroundColor: `var(--${color})`,
    top,
    bottom,
    left,
    right,
    opacity,
  };
  if (leftRound === true) {
    cssProperties.borderBottomLeftRadius = heightToUse / 2;
    cssProperties.borderTopLeftRadius = heightToUse / 2;
  }
  if (rightRound === true) {
    cssProperties.borderBottomRightRadius = heightToUse / 2;
    cssProperties.borderTopRightRadius = heightToUse / 2;
  }

  return (
    <div className={className} style={cssProperties} />
  );
}
