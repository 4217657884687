import { Trans } from 'react-i18next';
import React, { useState } from 'react';
import SlidingContentOverlay from './SlidingContentOverlay';
import ImprintContent from './ImprintContent';
import PrivacyPolicyContent from './PrivacyPolicyContent';
import './ContactLinks.scss';

export default function ContactLinks() {
  const [imprintVisible, setImprintVisible] = useState(false);
  const [privacyPolicyVisible, setPrivacyPolicyVisible] = useState(false);

  const anyOverlayOpen = imprintVisible || privacyPolicyVisible;

  return (
    <div className="ContactLinks">
      <div className={`ContactLinks-buttons${anyOverlayOpen ? ' hidden' : ''}`}>
        <button type="button" onClick={() => setImprintVisible(true)}>
          <Trans i18nKey="contact.imprint" />
        </button>
        <span className="ContactLinks-bullet">&nbsp;&#8226;&nbsp;</span>
        <button type="button" onClick={() => setPrivacyPolicyVisible(true)}>
          <Trans i18nKey="contact.privacyPolicy" />
        </button>
        <span className="ContactLinks-bullet">&nbsp;&#8226;&nbsp;</span>
        <button type="button">
          <a href="/Devaholics-AGB.pdf" target="_blank" rel="noreferrer" style={{ all: 'unset' }}>
            <Trans i18nKey="contact.generalTermsAndConditions" />
          </a>
        </button>
      </div>
      <SlidingContentOverlay
        visible={imprintVisible}
        onHide={() => setImprintVisible(false)}
        headerI18nKey="imprint.header"
      >
        <ImprintContent />
      </SlidingContentOverlay>
      <SlidingContentOverlay
        visible={privacyPolicyVisible}
        onHide={() => setPrivacyPolicyVisible(false)}
        headerI18nKey="privacyPolicy.header"
      >
        <PrivacyPolicyContent />
      </SlidingContentOverlay>
    </div>
  );
}
