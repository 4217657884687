import React, { useRef, useState } from 'react';
import './Services.scss';
import { Trans, useTranslation } from 'react-i18next';
import Page from '../../components/mobile/Page';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-big.svg';
import calculateAddressBarHeight from '../../components/mobile/CalculateAddressBarHeight';
import useOnResize from '../../components/common/UseOnResize';
import TextArrowButton from '../../components/common/TextArrowButton';
import ServiceAreas from '../common/ServiceAreas';

export default function Services() {
  const { t } = useTranslation();
  const [teaserMarginTop, setTeaserMarginTop] = useState(0);
  const teaserContainer = useRef<HTMLDivElement | null>(null);
  const introductionContainer = useRef<HTMLDivElement | null>(null);
  const servicesContainer = useRef<HTMLDivElement | null>(null);
  const initialAddressBarHeight = useRef(calculateAddressBarHeight());

  useOnResize(() => {
    if (teaserContainer.current === null) return;
    let teaserMargin = 0;
    if (window.innerHeight >= 450) {
      teaserMargin = teaserContainer.current.clientHeight + initialAddressBarHeight.current;
    }
    setTeaserMarginTop(-teaserMargin);
  });

  const onTeaserButton = () => {
    introductionContainer.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  const onIntroductionButton = () => {
    servicesContainer.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  };

  return (
    <Page lightGreyBackground>
      <div className="Services">
        <div
          className="Services-teaser"
          ref={teaserContainer}
          style={{ marginTop: `calc(${teaserMarginTop}px - 1rem)` }}
        >
          <p>
            <Trans i18nKey="services.teaser" />
          </p>
          <button type="button" onClick={onTeaserButton}>
            <ArrowDown className="Services-teaser-icon" />
          </button>
        </div>
        <div
          className="Services-services-introduction"
          ref={introductionContainer}
        >
          <p>
            <Trans i18nKey="services.introduction" />
          </p>
          <TextArrowButton
            color="purple"
            arrowPosition="down"
            text={t('services.servicesPresentation')}
            onClick={onIntroductionButton}
          />
        </div>
        <ServiceAreas ref={servicesContainer} />
      </div>
    </Page>
  );
}
