import React from 'react';
import { CustomerReference } from '../common/CustomerReferences';
import './CustomerBox.scss';
import PersonWithCompany from '../common/PersonWithCompany';
import CustomerReferenceText from '../common/CustomerReferenceText';

export default function CustomerBox({ customerReference }: {
  customerReference: CustomerReference
}) {
  const { logo, url } = customerReference;
  return (
    <article className="CustomerBox">
      <div className="CustomerBox-logo">
        <a href={url} target="_blank" rel="noreferrer">
          {logo}
        </a>
      </div>
      <p className="CustomerBox-text">
        <CustomerReferenceText customerReference={customerReference} />
      </p>
      <PersonWithCompany customerReference={customerReference} />
    </article>
  );
}
