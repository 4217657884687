import React from 'react';
import { ReactComponent as GastroworkLogo } from '../../assets/logos/logo-gastrowork.svg';
import { ReactComponent as DccsLogo } from '../../assets/logos/logo-dccs.svg';

export type CustomerReference = {
  logo: React.ReactNode,
  textI18nKey: 'dccs' | 'gastrowork',
  person: React.ReactNode,
  url: string,
  company: React.ReactNode,
};

const references: CustomerReference[] = [{
  logo: <GastroworkLogo />,
  textI18nKey: 'gastrowork',
  person: <span>Gregor&nbsp;Riedler & Richard&nbsp;Hacksteiner</span>,
  url: 'https://www.gastrowork.at',
  company: <span>gastro:work™ Personalagentur&nbsp;GmbH</span>,
}, {
  logo: <DccsLogo />,
  textI18nKey: 'dccs',
  person: 'Gerald Höglinger',
  url: 'https://www.dccs.at',
  company: 'DCCS GmbH',
}];
export default references;
