import React from 'react';
import DarkBackground from './DarkBackground';

export function StartBackground({ shifted }: { shifted?: boolean }) {
  return (
    <DarkBackground
      left="0px"
      right="-100px"
      rightRound
      shiftLeft={shifted}
    />
  );
}

export function ProjectsBackground({ shifted }: { shifted?: boolean }) {
  return (
    <DarkBackground
      left="-200px"
      right="20%"
      rightRound
      leftRound
      shiftRight={shifted}
    />
  );
}

export function TeamsBackground(
  { shiftRight, shiftLeft }:
  { shiftRight?: boolean, shiftLeft?: boolean },
) {
  return (
    <DarkBackground
      left="-75px"
      right="-75px"
      shiftRight={shiftRight}
      shiftLeft={shiftLeft}
      leftRound
      rightRound
    />
  );
}
