import React from 'react';
import CodeLine from '../common/CodeLine';
import useWindowSize from '../common/useWindowSize';

type Props = {
  left: string;
  right: string;
  leftRound?: boolean;
  rightRound?: boolean;
  shiftLeft?: boolean;
  shiftRight?: boolean;
};

export default function DarkBackground(props: Props) {
  const {
    leftRound, rightRound, left: leftProp, right: rightProp, shiftLeft, shiftRight,
  } = props;
  if (shiftLeft === true && shiftRight === true) {
    throw Error('Must not set both shift properties to true at the same time');
  }
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const screenOvershoot = 127;
  let left: string = leftProp;
  let right: string = rightProp;
  if (shiftLeft === true) {
    left = `calc(${leftProp} - ${windowWidth}px)`;
    right = `calc(${rightProp} + ${windowWidth}px)`;
  }
  if (shiftRight === true) {
    left = `calc(${leftProp} + ${windowWidth}px)`;
    right = `calc(${rightProp} - ${windowWidth}px)`;
  }

  return (
    <CodeLine
      color="background-dark-grey"
      left={left}
      right={right}
      top={-screenOvershoot}
      height={windowHeight + screenOvershoot * 2}
      leftRound={leftRound}
      rightRound={rightRound}
    />
  );
}
