import React from 'react';
import './ProjectsCarousel.scss';
import { Trans } from 'react-i18next';
import { ReactComponent as AppliqonLogo } from '../../assets/logos/logo-appliqon.svg';
import Chips from '../../components/common/Chips';
import Link from '../../components/common/Link';

type ProjectReference = {
  textGroupI18nKey: 'appliqon',
  logo: React.ReactNode,
  technologies: string[],
  url: string,
  urlText: string,
};

const projects: ProjectReference[] = [{
  textGroupI18nKey: 'appliqon',
  logo: <AppliqonLogo />,
  technologies: ['React', 'React Native', 'Expo', 'Material UI', 'Spring Boot', 'Java', 'GraphQL', 'Gradle', 'PostgreSQL', 'Github Actions'],
  url: 'https://www.appliqon.com/',
  urlText: 'www.appliqon.com',
}];

export default function ProjectsCarousel() {
  const {
    textGroupI18nKey, logo, technologies, url, urlText,
  } = projects[0];
  return (
    <div className="ProjectsCarousel">
      <article>
        <div className="ProjectsCarousel-logo">
          <div className="ProjectsCarousel-logo-background">
            <a href={url} target="_blank" rel="noreferrer">
              {logo}
            </a>
          </div>
        </div>
        <div className="ProjectsCarousel-link">
          <Link href={url}>
            {urlText}
          </Link>
        </div>
        <div className="ProjectsCarousel-challenge-solution">
          <div>
            <h3>
              <Trans i18nKey="projects.challenge" />
            </h3>
            <p>
              <Trans i18nKey={`projects.${textGroupI18nKey}.challenge`} />
            </p>
          </div>
          <div>
            <h3>
              <Trans i18nKey="projects.solution" />
            </h3>
            <p>
              <Trans i18nKey={`projects.${textGroupI18nKey}.solution`} />
            </p>
          </div>
        </div>
        <div className="ProjectsCarousel-story">
          <h3>
            <Trans i18nKey="projects.story" />
          </h3>
          <p>
            <Trans
              i18nKey={`projects.${textGroupI18nKey}.story`}
              components={{
                gastroworkLink: <Link href="https://www.gastrowork.at" />,
              }}
            />
          </p>
          <Chips texts={technologies} headerI18nKey="projects.technologies" />
        </div>
      </article>
    </div>
  );
}
