import paulPhoto from '../../assets/photos/paul.webp';
import philippPhoto from '../../assets/photos/philipp.webp';

export type TeamMember = {
  photoUrl: string,
  shortName: string,
  fullName: string,
  textGroupI18nKey: 'paul' | 'philipp',
  experiences: string[],
  linkedInId: string,
  githubId: string,
  cvFileName: string
};

const teamMembers: TeamMember[] = [{
  shortName: 'Paul',
  fullName: 'Paul Ganster',
  photoUrl: paulPhoto,
  textGroupI18nKey: 'paul',
  experiences: ['Typescript', 'React', 'Angular', 'Java', 'Kotlin', 'Spring Boot', 'Python', 'C#', 'C', 'C++', 'PHP', 'PostgreSQL',
    'MySQL', 'Github Actions', 'Jenkins', 'Docker'],
  linkedInId: 'paul-ganster',
  githubId: 'pganster',
  cvFileName: 'Paul%20Ganster%20CV',
}, {
  shortName: 'Philipp',
  fullName: 'Philipp Sommersguter',
  photoUrl: philippPhoto,
  textGroupI18nKey: 'philipp',
  experiences: ['Java', 'Spring Boot', 'Docker', 'Jenkins', 'Github Actions', 'GitLab', 'Ansible',
    'PostgreSQL', 'Gradle', 'Maven', 'C#', 'Unity3D', 'Typescript', 'React', 'Scrum', 'Kanban',
    'Jira', 'Confluence', 'Datadog'],
  linkedInId: 'philipp-sommersguter-b79368bb',
  githubId: 'psommersguter',
  cvFileName: 'Philipp%20Sommersguter%20CV',
}];

export default teamMembers;
