import React from 'react';
import './Chips.scss';
import { Trans } from 'react-i18next';
import { TFuncKey } from 'i18next';

function Chip({ children }: { children: React.ReactNode }) {
  return (
    <div className="Chip">
      {children}
    </div>
  );
}

type Props = {
  headerI18nKey: TFuncKey;
  texts: string[];
};

export default function Chips({ texts, headerI18nKey }: Props) {
  return (
    <div className="Chips">
      <h3 style={{ marginBottom: '1rem' }}>
        <Trans i18nKey={headerI18nKey} />
      </h3>
      <div className="Chips-container">
        {texts.map((text) => <Chip key={text}>{text}</Chip>)}
      </div>
    </div>
  );
}
