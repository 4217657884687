import { TFuncKey } from 'i18next';

export const mobileBreakpointPx = 1000;

export const transitionDurationMs = 300;

export const menuItemsDisplay: TFuncKey[] = [
  // If the menu items ever change, also adapt sitemap.xml
  'menu.services.displayText',
  'menu.projects.displayText',
  'menu.customers.displayText',
  'menu.team.displayText',
  'menu.contact.displayText',
];

export const menuItemsUrl: TFuncKey[] = [
  // If the menu items ever change, also adapt sitemap.xml
  'menu.services.url',
  'menu.projects.url',
  'menu.customers.url',
  'menu.team.url',
  'menu.contact.url',
];
