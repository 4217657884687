import React from 'react';
import './StickyTitle.scss';

export default function StickyTitle({ children }: { children: React.ReactNode }) {
  return (
    <div className="StickyTitle">
      <h1>{children}</h1>
    </div>
  );
}
