import React from 'react';
import { Trans } from 'react-i18next';
import { menuItemsDisplay } from '../../constants';
import { ReactComponent as LogoNoText } from '../../assets/logos/logo-no-text.svg';
import './Header.scss';
import goToPage from '../common/GoToPage';
import { usePage } from '../../app/common/PageContextProvider';

export default function Header() {
  const { currentPageIndex } = usePage();
  const menuIndexOfPage = currentPageIndex - 1;

  return (
    <header className="Header">
      <button
        className={`Header-logo-button${currentPageIndex === 0 ? ' hidden' : ''}`}
        type="button"
        onClick={() => goToPage(0)}
      >
        <LogoNoText className="Header-logo-no-text" />
      </button>
      <nav className="Header-menu-container">
        {menuItemsDisplay.map((menuItem, i) => (
          <button
            className={`Header-menu-item${i === menuIndexOfPage ? ' active' : ''}`}
            type="button"
            onClick={() => goToPage(i + 1)}
            key={menuItem}
          >
            <Trans i18nKey={menuItem} />
          </button>
        ))}
      </nav>
    </header>
  );
}
