import React from 'react';
import { Trans } from 'react-i18next';
import { CustomerReference } from './CustomerReferences';

export default function CustomerReferenceText(props: { customerReference: CustomerReference }) {
  const {
    customerReference: {
      textI18nKey,
    },
  } = props;
  return (
    <Trans i18nKey={`customers.references.${textI18nKey}`} />
  );
}
