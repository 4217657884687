import React, {
  createContext, Dispatch, SetStateAction, useContext, useMemo, useState,
} from 'react';

type UseState<T> = [
  T,
  Dispatch<SetStateAction<T>>,
];

type PageContextType = {
  currentPageIndexState: UseState<number>,
  updatePageOnScrollEnabledState: UseState<boolean>
};

const PageContext = createContext<PageContextType | null>(null);

type UsePage = {
  currentPageIndex: number,
  setCurrentPageIndex: (index: number) => void,
  updatePageOnScrollEnabled: boolean,
  setUpdatePageOnScrollEnabled: (enabled: boolean) => void
};

export function usePage(): UsePage {
  const {
    currentPageIndexState: [currentPageIndex, setCurrentPageIndex],
    updatePageOnScrollEnabledState: [updatePageOnScrollEnabled, setUpdatePageOnScrollEnabled],
  } = useContext(PageContext)!;
  return {
    currentPageIndex,
    setCurrentPageIndex,
    updatePageOnScrollEnabled,
    setUpdatePageOnScrollEnabled,
  };
}

export function PageContextProvider({ children }: { children: React.ReactNode }) {
  const currentPageIndexState = useState(0);
  const updatePageOnScrollEnabledState = useState(true);
  const pageContext = useMemo((): PageContextType => ({
    currentPageIndexState,
    updatePageOnScrollEnabledState,
  }), [currentPageIndexState, updatePageOnScrollEnabledState]);
  return (
    <PageContext.Provider value={pageContext}>
      {children}
    </PageContext.Provider>
  );
}
