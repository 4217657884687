import React from 'react';
import { Trans } from 'react-i18next';
import Page from '../../components/desktop/Page';
import ProjectsCarousel from '../common/ProjectsCarousel';
import CodeLine from '../../components/common/CodeLine';
import { ProjectsBackground } from '../../components/desktop/Backgrounds';
import './Projects.scss';

const background = (
  <>
    <ProjectsBackground />
    <CodeLine
      color="codeline-grey-transparentized"
      top={197}
      left={0}
      width={140}
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      bottom={75}
      left={110}
      width={230}
      rightRound
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      bottom={75}
      left={376}
      rightRound
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={157}
      right={0}
      width={241}
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={265}
      right={90}
      width={266}
      rightRound
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      height={416}
      right={0}
      width={377}
      bottom={-416 / 2}
      leftRound
    />
  </>
);
export default function Projects() {
  return (
    <Page index={2} background={background}>
      <div className="Projects">
        <div className="Projects-title">
          <h1>
            <Trans i18nKey="projects.title" />
          </h1>
        </div>
        <ProjectsCarousel />
      </div>
    </Page>
  );
}
