import { useEffect, useRef } from 'react';
import { usePage } from '../../app/common/PageContextProvider';
import { transitionDurationMs } from '../../constants';

/**
 * Make scrolling on the page toggleable. Use this when showing fixed positioned modal dialogs
 * to the user so that the user doesn't scroll the background, whilst preserving the original
 * scroll position.
 * Based on https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/.
 * @param isPageScrollEnabled Whether the page scroll is enabled
 */
export default function useTogglePageScroll(isPageScrollEnabled: boolean) {
  const { setUpdatePageOnScrollEnabled } = usePage();
  const repositionTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    clearTimeout(repositionTimeout.current);
    const { documentElement } = document;
    if (isPageScrollEnabled) {
      const positionTop = documentElement.style.top;
      if (positionTop === '') return;
      const scrollY = -parseInt(positionTop.substring(0, positionTop.length - 2));
      documentElement.style.position = '';
      documentElement.style.top = '';
      documentElement.style.left = '';
      documentElement.style.right = '';
      documentElement.scrollTo(0, scrollY);
      setUpdatePageOnScrollEnabled(true);
    } else {
      setUpdatePageOnScrollEnabled(false);
      // Wait for next render cycle via setTimeout to propagating updatePageOnScrollEnabled
      // flag so changing the documents scroll positioning won't mess up current page index
      repositionTimeout.current = setTimeout(() => {
        const scrollY = documentElement.scrollTop;
        documentElement.style.position = 'fixed';
        documentElement.style.top = `-${scrollY}px`;
        documentElement.style.left = '0';
        documentElement.style.right = '0';
      }, transitionDurationMs);
    }
  }, [isPageScrollEnabled, setUpdatePageOnScrollEnabled]);
}
