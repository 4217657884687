import React from 'react';
import './AppRoot.scss';
import AppMobile from './app/mobile/App';
import AppDesktop from './app/desktop/App';
import useIsMobile from './components/common/UseIsMobile';
import { PageContextProvider } from './app/common/PageContextProvider';

export default function AppRoot() {
  const isMobile = useIsMobile();

  return (
    <PageContextProvider>
      <div className="AppRoot">
        {isMobile ? <AppMobile /> : <AppDesktop />}
      </div>
    </PageContextProvider>
  );
}
