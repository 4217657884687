import React from 'react';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-left-orange.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right-orange.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/arrow-down-small-orange.svg';
import { ReactComponent as ArrowUp } from '../../assets/icons/arrow-up-small-orange.svg';
import './TextArrowButton.scss';

type Props = {
  arrowPosition: 'left' | 'right' | 'down' | 'up',
  text: string,
  onClick: () => void,
  color?: 'orange' | 'purple',
};

export default function TextArrowButton(props: Props) {
  const {
    arrowPosition, text, onClick, color: colorProp,
  } = props;

  const color = colorProp ?? 'orange';

  return (
    <button type="button" className={`TextArrowButton TextArrowButton-${color}`} onClick={onClick}>
      {arrowPosition === 'down' && <ArrowDown className="TextArrowButton-icon TextArrowButton-arrow-left-side" />}
      {arrowPosition === 'up' && <ArrowUp className="TextArrowButton-icon TextArrowButton-arrow-left-side" />}
      {arrowPosition === 'left' && <ArrowLeft className="TextArrowButton-icon TextArrowButton-arrow-left-side" />}
      {text}
      {arrowPosition === 'right' && <ArrowRight className="TextArrowButton-icon TextArrowButton-arrow-right-side" />}
    </button>
  );
}
