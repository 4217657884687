import React, { useRef, WheelEvent } from 'react';
import './App.scss';
import Header from '../../components/desktop/Header';
import Start from '../../start/desktop/Start';
import Customers from '../../customers/desktop/Customers';
import Projects from '../../projects/desktop/Projects';
import Team from '../../team/desktop/Team';
import ContactButton from '../../components/common/ContactButton';
import Services from '../../services/desktop/Services';
import Contact from '../../contact/desktop/Contact';
import useSyncScrollWithPageIndex from '../common/UseSyncScrollWithPageIndex';
import useSyncPageIndexWithUrl from '../common/UseSyncPageIndexWithUrl';

export default function App() {
  const pagesContainerRef = useRef<HTMLDivElement | null>(null);
  const getPageIndexToCurrentScrollPosition = () => Math.round(
    pagesContainerRef.current!.scrollLeft / window.innerWidth,
  );

  const getScrollPosition = (index: number) => ({ left: window.innerWidth * index });

  useSyncScrollWithPageIndex(
    getScrollPosition,
    getPageIndexToCurrentScrollPosition,
    pagesContainerRef,
  );
  useSyncPageIndexWithUrl(getScrollPosition, pagesContainerRef);

  const onWheel = ({ deltaY }: WheelEvent<HTMLDivElement>) => {
    if (pagesContainerRef.current === null) return;
    pagesContainerRef.current.scrollLeft += deltaY * 1.5;
  };

  return (
    <div className="App desktop">
      <Header />
      <main className="App-pages" onWheel={onWheel} ref={pagesContainerRef}>
        <Start />
        <Services />
        <Projects />
        <Customers />
        <Team />
        <Contact />
      </main>
      <ContactButton />
    </div>
  );
}
