import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Page from '../../components/mobile/Page';
import CodeLine from '../../components/common/CodeLine';
import './Team.scss';
import teamMembers, { TeamMember } from '../common/TeamMembers';
import TextArrowButton from '../../components/common/TextArrowButton';
import TeamMemberDetail from '../common/TeamMemberDetail';
import StickyTitle from '../../contact/mobile/StickyTitle';

const marginTop = 250;

const background = (
  <>
    <CodeLine
      color="codeline-grey"
      top={26 - marginTop}
      right={0}
      width={62}
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={26 - marginTop}
      right={78}
      width={100}
      leftRound
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={92 - marginTop}
      left={0}
      width={27}
      rightRound
    />
    <CodeLine
      color="codeline-grey"
      top={92 - marginTop}
      left={43}
      width={152}
      leftRound
      rightRound
    />
  </>
);

function TeamMemberView({ member }: { member: TeamMember }) {
  const {
    photoUrl, fullName, shortName, textGroupI18nKey,
    // experiences, linkedInId, githubId, resumeFileName,
  } = member;
  const { t } = useTranslation();
  const photoContainer = useRef<HTMLDivElement | null>(null);
  const [isDetailShown, setIsDetailShown] = useState(false);

  const onHideDetail = () => {
    setIsDetailShown(false);
    photoContainer.current!.scrollIntoView();
  };

  return (
    <article className="TeamMemberView">
      <div className="TeamMemberView-photo" ref={photoContainer}>
        <img src={photoUrl} alt={`${fullName} photo`} />
      </div>
      {isDetailShown
        && <TeamMemberDetail member={member} onHideDetail={onHideDetail} />}
      {!isDetailShown && (
        <>
          <h2 className="TeamMemberView-name">{fullName}</h2>
          <p className="TeamMemberView-teaser"><Trans i18nKey={`team.${textGroupI18nKey}.teaser`} /></p>
          <TextArrowButton
            onClick={() => setIsDetailShown(true)}
            arrowPosition="down"
            text={t('team.showDetail', { name: shortName })}
          />
        </>
      )}
    </article>
  );
}

export default function Team() {
  return (
    <Page background={background} lightGreyBackground>
      <div className="Team" style={{ marginTop }}>
        <StickyTitle>
          <Trans i18nKey="team.title" />
        </StickyTitle>
        <div className="Team-members">
          {teamMembers.map((member) => <TeamMemberView key={member.shortName} member={member} />)}
        </div>
      </div>
    </Page>
  );
}
