import { useState } from 'react';
import useOnResize from './UseOnResize';

export default function useWindowSize() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useOnResize(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  });

  return {
    width, height,
  };
}
