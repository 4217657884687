import React from 'react';
import Page from '../../components/mobile/Page';
import './Footer.scss';
import CodeLine from '../../components/common/CodeLine';
import ContactLinks from '../common/ContactLinks';

const background = (
  <>
    <CodeLine
      color="purple"
      top={0}
      right={0}
      width={52}
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={0}
      right={68}
      width={85}
      leftRound
      rightRound
    />
    <div className="Footer-background">
      <div />
    </div>
  </>
);
export default function Footer() {
  return (
    <Page background={background}>
      <footer className="Footer">
        <ContactLinks />
      </footer>
    </Page>
  );
}
