import React from 'react';
import { Trans } from 'react-i18next';
import Page from '../../components/desktop/Page';
import CodeLine from '../../components/common/CodeLine';
import { TeamsBackground } from '../../components/desktop/Backgrounds';
import './Contact.scss';
import ContactMailButton from '../common/ContactMailButton';
import ContactLinks from '../common/ContactLinks';

const background = (
  <div className="Contact-background">
    <TeamsBackground shiftLeft />
    <CodeLine
      color="codeline-grey-darkened"
      top={-153}
      left={0}
      width={315}
      height={401}
      rightRound
    />

    <CodeLine
      color="codeline-grey-darkened"
      top={-153}
      left={449}
      width={401}
      height={401}
      rightRound
      leftRound
    />
    <CodeLine
      color="purple"
      top={218}
      right={72}
      width={345}
      leftRound
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={320}
      right={688}
      width={227}
      leftRound
      rightRound
    />
    <CodeLine
      color="codeline-grey"
      top={320}
      right={250}
      width={406}
      leftRound
      rightRound
    />
    <CodeLine
      className="Contact-background-adjustable-line"
      color="orange"
      opacity={0.9}
      bottom={418}
      right={0}
      width={215}
      leftRound
    />
    <CodeLine
      className="Contact-background-adjustable-line"
      color="background-dark-grey"
      bottom={-300}
      right={0}
      width={818}
      height={600}
      leftRound
    />
    <CodeLine
      color="codeline-grey"
      bottom={118}
      right={939}
      leftRound
      rightRound
    />
    <CodeLine
      color="codeline-grey"
      bottom={118}
      right={686}
      width={217}
      leftRound
      rightRound
    />
  </div>
);

export default function Contact() {
  return (
    <Page index={5} background={background}>
      <div className="Contact">
        <div className="Contact-title">
          <h1>
            <Trans i18nKey="contact.title" />
          </h1>
        </div>
        <p className="Contact-text">
          <Trans i18nKey="contact.text" />
        </p>
        <ContactMailButton />
        <ContactLinks />
      </div>
    </Page>
  );
}
