import React from 'react';
import { Trans } from 'react-i18next';
import MailAddress from '../../components/desktop/MailAddress';

export default function ImprintContent() {
  return (
    <>
      <h3><Trans i18nKey="imprint.name.header" /></h3>
      <p><Trans i18nKey="imprint.name.content" /></p>
      <h3><Trans i18nKey="imprint.managingDirectors.header" /></h3>
      <p><Trans i18nKey="imprint.managingDirectors.content" /></p>
      <h3><Trans i18nKey="imprint.companyObject.header" /></h3>
      <p><Trans i18nKey="imprint.companyObject.content" /></p>
      <h3><Trans i18nKey="imprint.contactData" /></h3>
      <p>
        <MailAddress />
      </p>
      <h3><Trans i18nKey="imprint.address.header" /></h3>
      <p><Trans i18nKey="imprint.address.content" /></p>
      <h3><Trans i18nKey="imprint.vat.header" /></h3>
      <p><Trans i18nKey="imprint.vat.content" /></p>
      <h3><Trans i18nKey="imprint.registrationNumber.header" /></h3>
      <p><Trans i18nKey="imprint.registrationNumber.content" /></p>
      <h3><Trans i18nKey="imprint.registrationCourt.header" /></h3>
      <p><Trans i18nKey="imprint.registrationCourt.content" /></p>
      <h3><Trans i18nKey="imprint.chamberOfCommerceNotice.header" /></h3>
      <p><Trans i18nKey="imprint.chamberOfCommerceNotice.content" /></p>
      <h3><Trans i18nKey="imprint.photoMaterial.header" /></h3>
      <p><Trans i18nKey="imprint.photoMaterial.content" /></p>
    </>
  );
}
