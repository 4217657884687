import React from 'react';
import { Trans } from 'react-i18next';
import Page from '../../components/desktop/Page';
import CodeLine from '../../components/common/CodeLine';
import { TeamsBackground } from '../../components/desktop/Backgrounds';
import './Customers.scss';
import references from '../common/CustomerReferences';
import CustomerBox from './CustomerBox';

const background = (
  <>
    <CodeLine
      color="codeline-grey-transparentized"
      top={157}
      left={0}
      width={140}
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      height={416}
      left={0}
      width={487}
      bottom={-416 / 2}
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={167}
      right={529}
      rightRound
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={167}
      right={255}
      width={238}
      rightRound
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={269}
      right={90}
      width={311}
      rightRound
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      right={327}
      width={192}
      bottom={75}
      leftRound
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      right={219}
      bottom={75}
      leftRound
      rightRound
    />
    <TeamsBackground shiftRight />
  </>
);

export default function Customers() {
  return (
    <Page index={3} background={background}>
      <div className="Customers">
        <div className="Customers-title">
          <h1>
            <Trans i18nKey="customers.title" />
          </h1>
        </div>
        <div className="Customers-references">
          {references.map((customer, i) => (
            <CustomerBox
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              customerReference={customer}
            />
          ))}
        </div>
      </div>
    </Page>
  );
}
