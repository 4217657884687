/**
 * Calculates the address bar height.
 * Based on https://stackoverflow.com/a/64836824.
 * @return Address bar height of the current browser in pixel.
 */
export default function calculateAddressBarHeight() {
  const div = document.createElement('div');
  div.style.height = '100vh';
  div.style.width = '0px';
  div.style.position = 'fixed';

  document.body.appendChild(div);
  const addressBarHeight = div.clientHeight - window.innerHeight;
  document.body.removeChild(div);
  return addressBarHeight;
}
