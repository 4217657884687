import { menuItemsDisplay } from '../../constants';

export const goToPageEventName = 'goToPage';

export type GoToPageInfo = {
  index: number,
};

export default function goToPage(pageIndex: number) {
  if (pageIndex < 0 || pageIndex > menuItemsDisplay.length) {
    throw Error('Invalid page index supplied');
  }
  const event = new CustomEvent<GoToPageInfo>(goToPageEventName, {
    detail: {
      index: pageIndex,
    },
  });
  document.dispatchEvent(event);
}
