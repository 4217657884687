import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { TeamMember } from './TeamMembers';
import { ReactComponent as GithubIcon } from '../../assets/icons/github.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedin.svg';
import Chips from '../../components/common/Chips';
import TextArrowButton from '../../components/common/TextArrowButton';
import './TeamMemberDetail.scss';
import useIsMobile from '../../components/common/UseIsMobile';
import { ReactComponent as OpenInNew } from '../../assets/icons/open-in-new.svg';
import Link from '../../components/common/Link';

type TeamMemberDetailProps = {
  member: TeamMember,
  onHideDetail: () => void
};

export default function TeamMemberDetail(props: TeamMemberDetailProps) {
  const {
    member: {
      fullName, textGroupI18nKey, experiences, linkedInId, githubId, cvFileName,
    },
    onHideDetail,
  } = props;
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  return (
    <article className="TeamMemberDetail">
      <div className="TeamMemberDetail-header">
        <h2>{fullName}</h2>
        <a href={`https://github.com/${githubId}`} target="_blank" rel="noreferrer">
          <GithubIcon className="TeamMemberDetail-header-icon" />
        </a>
        <a href={`https://linkedin.com/in/${linkedInId}`} target="_blank" rel="noreferrer">
          <LinkedInIcon className="TeamMemberDetail-header-icon" />
        </a>
      </div>
      <div className="TeamMemberDetail-text text">
        <Trans
          i18nKey={`team.${textGroupI18nKey}.detail`}
          components={{
            li: <li />,
            ul: <ul />,
          }}
        />
      </div>
      <Chips texts={experiences} headerI18nKey="team.experiences" />
      <div className="TeamMemberDetail-button">
        {isMobile && (
          <>
            <div style={{
              marginTop: '2rem',
            }}
            >
              <Link
                href={`/cvs/${cvFileName}.pdf`}
              >
                <OpenInNew className="TeamMemberDetail-cv-icon" />
                <Trans i18nKey="team.showCV" />
              </Link>
            </div>
            <TextArrowButton
              onClick={onHideDetail}
              arrowPosition="up"
              text={t('team.hideDetail')}
            />
          </>
        )}
        {!isMobile && (
          <>
            <TextArrowButton
              onClick={onHideDetail}
              arrowPosition="left"
              text={t('team.showOverview')}
            />
            <Link
              style={{
                marginLeft: '3rem',
              }}
              href={`/cvs/${cvFileName}.pdf`}
            >
              <OpenInNew className="TeamMemberDetail-cv-icon" />
              <Trans i18nKey="team.showCV" />
            </Link>
          </>
        )}
      </div>
    </article>
  );
}
