import React from 'react';
import './Page.scss';

type Props = {
  children: React.ReactNode,
  lightGreyBackground?: boolean,
  background?: React.ReactNode
};

export default function Page(props: Props) {
  const { children, background, lightGreyBackground } = props;
  return (
    <div className="Page">
      <div className={lightGreyBackground ? 'Page-light-grey-background' : ''}>
        <section className="Page-content">
          {children}
        </section>
      </div>
      {background && (
        <div className="Page-background">
          {background}
        </div>
      )}
    </div>
  );
}
