import React from 'react';

/**
 * To prevent web scrapers from loading the website and parsing the email address, we encode the
 * email address using https://www.browserling.com/tools/text-to-html-entities before adding
 * it to the web page. We must not set the mail address via JS either, because modern web scrapers
 * can parse SPAs too.
 */
export default function MailAddress() {
  return (
    <span>
      {/* eslint-disable-next-line max-len */}
      &#x63;&#x6f;&#x6e;&#x74;&#x61;&#x63;&#x74;&#x40;&#x64;&#x65;&#x76;&#x61;&#x68;&#x6f;&#x6c;&#x69;&#x63;&#x73;&#x2e;&#x69;&#x6f;
    </span>
  );
}
