import { useEffect } from 'react';

/**
 * Hook that adds an event listener to the global window object. The onResize parameter is fired
 * on initial render and on every window resize event.
 * @param onResize The function that is called.
 */
export default function useOnResize(onResize: () => void) {
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
