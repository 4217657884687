import { MutableRefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { menuItemsUrl } from '../../constants';
import { usePage } from './PageContextProvider';

export default function useSyncPageIndexWithUrl(
  getScrollPosition: (index: number) => ScrollToOptions,
  pagesContainerRef: MutableRefObject<HTMLDivElement | null> | null = null,
) {
  const { currentPageIndex, setCurrentPageIndex } = usePage();
  const { t } = useTranslation();

  useEffect(() => {
    const scrollContainer = pagesContainerRef === null
      ? document.documentElement
      : pagesContainerRef.current!;

    const foundItem = menuItemsUrl.find(
      (menuItem) => `/${t(menuItem)}`.toLowerCase() === window.location.pathname,
    );
    if (foundItem === undefined) {
      window.history.replaceState(null, '', '/');
    } else {
      const newPageIndex = menuItemsUrl.indexOf(foundItem) + 1;
      scrollContainer.scrollTo(getScrollPosition(newPageIndex));
      setCurrentPageIndex(newPageIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // If the URLs ever change, also adapt sitemap.xml
    const url = currentPageIndex === 0 ? '' : t(menuItemsUrl[currentPageIndex - 1]);
    window.history.replaceState(null, '', `/${url}`.toLowerCase());
  }, [currentPageIndex, t]);
}
