import React from 'react';
import Page from '../../components/mobile/Page';
import { ReactComponent as LogoLarge } from '../../assets/logos/logo-large.svg';
import './Start.scss';
import CodeLine from '../../components/common/CodeLine';

const startBackground = (
  <>
    <CodeLine
      color="orange"
      left={0}
      top={58}
      width={71}
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      left={87}
      top={58}
      width={81}
      leftRound
      rightRound
    />
    <CodeLine
      color="purple"
      right={0}
      top={346}
      width={88}
      leftRound
      className="Start-background-hideable-line"
    />
    <CodeLine
      color="background-light-grey"
      right={104}
      top={346}
      leftRound
      rightRound
      className="Start-background-hideable-line"
    />
  </>
);

export default function Start() {
  return (
    <Page background={startBackground}>
      <div className="Start">
        <LogoLarge className="Start-logo" />
      </div>
    </Page>
  );
}
