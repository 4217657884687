import { Trans } from 'react-i18next';
import React, { ForwardedRef, forwardRef } from 'react';

export default forwardRef((props, ref: ForwardedRef<HTMLDivElement>) => (
  <div className="ServiceAreas" ref={ref}>
    <div className="ServiceAreas-product">
      <h2>
        <Trans i18nKey="services.productDevelopment" />
      </h2>
      <h3>
        <Trans i18nKey="services.visionToProduct" />
      </h3>
      <p>
        <Trans i18nKey="services.productDescription" />
      </p>
    </div>
    <div className="ServiceAreas-consulting">
      <h2>
        <Trans i18nKey="services.consulting" />
      </h2>
      <h3>
        <Trans i18nKey="services.expertToRent" />
      </h3>
      <p>
        <Trans i18nKey="services.consultingDescription" />
      </p>
    </div>
  </div>
));
