import React, { useState } from 'react';
import './Header.scss';
import { Trans } from 'react-i18next';
import { menuItemsDisplay } from '../../constants';
import goToPage from '../common/GoToPage';
import CodeLine from '../common/CodeLine';
import { usePage } from '../../app/common/PageContextProvider';
import useTogglePageScroll from './UseTogglePageScroll';

export default function Header() {
  const { currentPageIndex } = usePage();
  const menuIndexOfPage = currentPageIndex - 1;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useTogglePageScroll(!isMenuOpen);

  const onGoToPage = (pageIndex: number) => {
    setIsMenuOpen(false);
    // Wait for next render cycle to complete, so menu is closed and scroll of document
    // is restored. Only afterwards, update the page index which changes the documents
    // scroll position again.
    setTimeout(() => {
      goToPage(pageIndex);
    });
  };

  return (
    <header className={`Header${isMenuOpen ? ' open' : ''}`}>
      <button
        type="button"
        className="Header-button"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <div className="Header-button-lines">
          <div className="Header-button-top-line" />
          <div className="Header-button-bottom-line" />
        </div>
      </button>
      <nav className="Header-menu-container">
        {menuItemsDisplay.map((menuItem, i) => (
          <button
            className={`Header-menu-item${i === menuIndexOfPage ? ' active' : ''}`}
            type="button"
            onClick={() => onGoToPage(i + 1)}
            key={menuItem}
          >
            <Trans i18nKey={menuItem} />
          </button>
        ))}
        <div className="Header-menu-container-background">
          <CodeLine
            color="purple"
            bottom={50}
            right={0}
            width={100}
            leftRound
          />
          <CodeLine
            color="codeline-grey"
            bottom={50}
            right={116}
            leftRound
            rightRound
          />
          <CodeLine
            color="codeline-grey"
            bottom={150}
            left={0}
            width={80}
            rightRound
          />
          <CodeLine
            color="codeline-grey-transparentized"
            bottom={150}
            left={96}
            width={70}
            rightRound
            leftRound
          />
        </div>
      </nav>
    </header>
  );
}
