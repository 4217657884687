import React from 'react';
import './ContactButton.scss';
import { Trans } from 'react-i18next';
import { menuItemsDisplay } from '../../constants';
import goToPage from './GoToPage';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import { usePage } from '../../app/common/PageContextProvider';

export default function ContactButton() {
  const { currentPageIndex } = usePage();
  const contactPageIndex = menuItemsDisplay.length;
  return (
    <button
      className={`ContactButton${currentPageIndex === 0 || currentPageIndex === contactPageIndex ? ' hidden' : ''}`}
      type="button"
      onClick={() => goToPage(contactPageIndex)}
    >
      <EmailIcon className="ContactButton-icon" />
      <span className="ContactButton-text">
        <Trans i18nKey="common.contactUs" />
      </span>
    </button>
  );
}
