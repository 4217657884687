import React, { useRef } from 'react';
import './App.scss';
import Start from '../../start/mobile/Start';
import Services from '../../services/mobile/Services';
import Projects from '../../projects/mobile/Projects';
import Customers from '../../customers/mobile/Customers';
import Team from '../../team/mobile/Team';
import Contact from '../../contact/mobile/Contact';
import Footer from '../../contact/mobile/Footer';
import Header from '../../components/mobile/Header';
import useSyncScrollWithPageIndex from '../common/UseSyncScrollWithPageIndex';
import useSyncPageIndexWithUrl from '../common/UseSyncPageIndexWithUrl';
import ContactButton from '../../components/common/ContactButton';

const pageReachedThresholdInPx = 300;
export default function App() {
  const pagesContainerRef = useRef<HTMLDivElement | null>(null);

  const getPageContainers = (): HTMLElement[] => Array.from(
    pagesContainerRef.current!.getElementsByClassName('Page'),
  ) as HTMLElement[];

  const getPageIndexToCurrentScrollPosition = () => {
    const pages = getPageContainers();
    const scrollPosition = document.documentElement.scrollTop;
    if (scrollPosition + window.innerHeight + pageReachedThresholdInPx
      >= document.documentElement.scrollHeight) {
      return pages.length - 1;
    }

    const pageToScrollTo = pages
      .sort((a, b) => a.offsetTop - b.offsetTop)
      .find((a) => a.offsetTop > scrollPosition + pageReachedThresholdInPx)!;
    return pages.indexOf(pageToScrollTo) - 1;
  };

  const getScrollPosition = (pageIndex: number): ScrollToOptions => {
    const pageContainer = getPageContainers()[pageIndex];

    const marginTop = parseInt(window.getComputedStyle(pageContainer.querySelector('section > *')!).marginTop);
    return { top: pageContainer.offsetTop - marginTop };
  };

  useSyncScrollWithPageIndex(getScrollPosition, getPageIndexToCurrentScrollPosition);
  useSyncPageIndexWithUrl(getScrollPosition);

  return (
    <div className="App mobile">
      <Header />
      <main className="App-pages" ref={pagesContainerRef}>
        <Start />
        <Services />
        <Projects />
        <Customers />
        <Team />
        <Contact />
      </main>
      <Footer />
      <ContactButton />
    </div>
  );
}
