import React from 'react';
import { Trans } from 'react-i18next';
import MailAddress from '../../components/desktop/MailAddress';
import MailToAction from './MailToAction';
import './PrivacyPolicyContent.scss';

export default function PrivacyPolicyContent() {
  return (
    <div className="PrivacyPolicyContent">
      <h3><Trans i18nKey="privacyPolicy.safety.header" /></h3>
      <p><Trans i18nKey="privacyPolicy.safety.content" />
      </p>
      <h3><Trans i18nKey="privacyPolicy.dataUsageContact.header" /></h3>
      <p><Trans i18nKey="privacyPolicy.dataUsageContact.content" />
      </p>
      <h3><Trans i18nKey="privacyPolicy.rights.header" /></h3>
      <p><Trans i18nKey="privacyPolicy.rights.content" />
      </p>
      <h3><Trans i18nKey="privacyPolicy.enquiries.header" /></h3>
      <MailToAction style={{
        color: 'var(--orange)',
        borderBottom: 'dotted 1px',
      }}
      >
        <MailAddress />
      </MailToAction>
    </div>
  );
}
