import React from 'react';

type Props = {
  className?: string | undefined
  style?: React.CSSProperties | undefined
  children: React.ReactNode,
};

export default function MailToAction(props: Props) {
  const { className, style, children } = props;
  const emailTo = window.atob('Y29udGFjdEBkZXZhaG9saWNzLmlv');

  function openMailTo() {
    window.open(`mailto:${emailTo}`, '_blank');
  }

  return (
    <button className={className} style={style} onClick={openMailTo} type="button">
      {children}
    </button>
  );
}
