import { MutableRefObject, useEffect } from 'react';
import { goToPageEventName, GoToPageInfo } from '../../components/common/GoToPage';
import { usePage } from './PageContextProvider';

export default function useSyncScrollWithPageIndex(
  getScrollPosition: (index: number) => ScrollToOptions,
  getPageIndexForCurrentScrollPosition: () => number,
  scrollableContainerRef: MutableRefObject<Element | null> | null = null,
) {
  const {
    setCurrentPageIndex,
    updatePageOnScrollEnabled,
    setUpdatePageOnScrollEnabled,
  } = usePage();

  const addScrollListener = (onScroll: () => void) => {
    if (scrollableContainerRef === null) {
      document.addEventListener('scroll', onScroll);
    } else {
      scrollableContainerRef.current?.addEventListener('scroll', onScroll);
    }
  };

  const removeScrollListener = (onScroll: () => void) => {
    if (scrollableContainerRef === null) {
      document.removeEventListener('scroll', onScroll);
    } else {
      scrollableContainerRef.current?.removeEventListener('scroll', onScroll);
    }
  };

  const scrollTo = (options: ScrollToOptions) => {
    if (scrollableContainerRef === null) {
      document.documentElement.scrollTo(options);
    } else {
      scrollableContainerRef.current?.scrollTo(options);
    }
  };

  useEffect(() => {
    let onScroll: () => void;
    /**
     * Called when smooth scrollTo is completed, which is called after a GoToPage event has fired.
     * Since the duration of a smooth scroll is browser specific, we can't use a specific timestamp
     * to wait. The user also may interrupt the smooth scrolling animation, which is why we can't
     * wait for the pageContainer to reach a specific position.
     * Therefore, we need to listen every 100ms if the browser is still scrolling, and if not, we
     * can re-enable updating the page index state on user scrolling.
     */
    const onSmoothScrollToFinished = () => {
      setCurrentPageIndex(getPageIndexForCurrentScrollPosition());
      setUpdatePageOnScrollEnabled(true);
      removeScrollListener(onScroll);
    };

    let scrollTimeout: NodeJS.Timeout | undefined = undefined;
    onScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(onSmoothScrollToFinished, 100);
    };

    const onGoToPage = ({ detail: { index } }: CustomEvent<GoToPageInfo>) => {
      addScrollListener(onScroll);
      setUpdatePageOnScrollEnabled(false);
      scrollTo({
        ...getScrollPosition(index),
        behavior: 'smooth',
      });
    };

    const onGoToPageEvent = onGoToPage as EventListener;
    document.addEventListener(goToPageEventName, onGoToPageEvent);
    return () => document.removeEventListener(goToPageEventName, onGoToPageEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (!updatePageOnScrollEnabled) return;
      setCurrentPageIndex(getPageIndexForCurrentScrollPosition());
    };
    addScrollListener(onScroll);
    return () => removeScrollListener(onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePageOnScrollEnabled]);
}
