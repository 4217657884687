import React from 'react';
import './Link.scss';

type Props = {
  href: string,
  children?: React.ReactNode
  style?: React.CSSProperties
};

export default function Link({ href, children, style }: Props) {
  return (
    <a className="Link" href={href} target="_blink" rel="noreferrer" style={style}>
      {children ?? href}
    </a>
  );
}
