import React from 'react';
import Page from '../../components/desktop/Page';
import { ReactComponent as LogoLarge } from '../../assets/logos/logo-large.svg';
import './Start.scss';
import CodeLine from '../../components/common/CodeLine';
import { StartBackground } from '../../components/desktop/Backgrounds';

const startBackground = (
  <>
    <StartBackground />
    <CodeLine
      color="purple"
      bottom={75}
      right={0}
      width={360}
      leftRound
    />
    <CodeLine
      color="codeline-grey"
      bottom={75}
      right={396}
      rightRound
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      bottom={75}
      left={0}
      width={160}
      rightRound
    />
    <CodeLine
      color="orange"
      top={80}
      left={0}
      width={142}
      rightRound
    />
    <CodeLine
      color="codeline-grey"
      top={188}
      left={0}
      width={254}
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={188}
      left={290}
      width={227}
      rightRound
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={80}
      right={154}
      width={300}
      rightRound
      leftRound
    />
  </>
);

export default function Start() {
  return (
    <Page index={0} background={startBackground}>
      <div className="Start">
        <LogoLarge />
      </div>
    </Page>
  );
}
