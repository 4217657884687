const de = {
  common: {
    contactUs: 'Kontakt aufnehmen',
  },
  menu: {
    // If urls are adapted, be sure to adapt sitemap.xml too
    services: {
      displayText: 'Leistungen',
      url: 'services',
    },
    projects: {
      displayText: 'Projekte',
      url: 'projects',
    },
    customers: {
      displayText: 'Kunden',
      url: 'customers',
    },
    team: {
      displayText: 'Team',
      url: 'team',
    },
    contact: {
      displayText: 'Kontakt',
      url: 'contact',
    },
  },
  services: {
    // \u00AD = soft hyphen. Using hyphens: manual in the CSS, the browser will break
    // the word at this point with a hyphen when there is not enough place
    teaser: 'Was passiert, wenn zwei Programmierer ihre Begeisterung an der Software\u00ADentwicklung in die Welt tragen wollen?',
    introduction: 'Sie gründen die Devaholics – bei uns ist der Name Programm, denn wir sind „addicted to building software“. Unsere Vision ist es Effizienz und Qualität als obersten Standard in der Softwareentwicklung zu schaffen.',
    servicesPresentation: 'Dabei spezialisieren wir uns auf folgende Bereiche',
    productDevelopment: 'Produktentwicklung',
    visionToProduct: 'Von der Vision bis zum fertigen Produkt im Betrieb',
    productDescription: 'Du willst deine Idee schnell und einfach realisieren? Auf unser Team ist Verlass. Als agile Softwareentwickler erstellen wir Produkte, die auf die Bedürfnisse unserer Kunden abgestimmt und dabei sowohl intuitiv als auch ansprechend sind.\n\nGemeinsam mit dir arbeiten wir Ideen aus und iterieren vom groben Entwurf zum MVP bis zur Software im Produktiveinsatz in möglichst effizienter Weise. Anforderungsänderungen sind bei uns keine Qual sondern erwartet, denn eine Software, ist ein Produkt, das lebt und sich stetig weiterentwickelt!',
    consulting: 'Consulting',
    expertToRent: 'Expertenwissen zum Mieten',
    consultingDescription: 'Du bist auf der Suche nach Unterstützung für dein Team? Dann bist du bei uns richtig. Wir sind eng zusammengeschweißte Kollegen mit jahrelanger Erfahrung in diversen Unternehmen und Branchen, die ihr Know-How gerne in dein Unternehmen einbringen.\n\nWir gliedern uns problemlos in kleine, sowie große Teams ein und können gemeinsam Synergieeffekte nutzen. Dabei freuen wir uns immer, wenn sich auch die eine oder andere Aktivität nach der Arbeit ergibt, denn Zusammenhalt ist das was Teams stark macht!',
  },
  projects: {
    title: 'Erfolgreiche Projekte',
    challenge: 'Die Herausforderung',
    solution: 'Die Lösung',
    technologies: 'Die Technologien',
    story: 'Die Story',
    appliqon: {
      challenge: 'Die Digitalisierung von Jobangeboten an den eigenen Personalpool',
      solution: 'Die Web Plattform Appliqon inklusive Jobvergabe via mobile App',
      story: 'Gemeinsam mit der <gastroworkLink>gastro:work™ Personalagentur</gastroworkLink> schufen wir das nächste '
        + 'digitale Level - eine Software Gesamtlösung für die Arbeitskräfteüberlassung. '
        + 'Eine Personalplanung am Puls der Zeit und eine maximal benutzerfreundliche Software waren Grundanforderungen unseres Kunden. '
        + 'Zusätzlich zur Jobvergabe via mobile App bietet die Software ein auf die Terminverwaltung und Zeiterfassung aufbauendes Modul zur Fakturierung. '
        + 'Darüber hinaus exisitert eine Anbindung an das Lohnverrechnungssystem des Kunden um ELDA Anmeldungen durchzuführen und geleistete Stunden der Mitarbeiter effizient zu erfassen.',
    },
  },
  customers: {
    title: 'Zufriedene Kunden',
    references: {
      gastrowork: 'Die Entwicklung und Umsetzung unserer Software APPLIQON war von Beginn an ein strukturiertes und perfekt geplantes Projekt. Ob beim Auftakt Workshop, bei den Sprint Reviews oder Refinements, wir wussten unser Projekt stets in guten Händen. Da unsere Software in einem stetigen Weiterentwicklungsprozess steht, freuen wir uns auf eine weiterhin perfekte Zusammenarbeit mit Devaholics.',
      dccs: 'Philipp und Paul treten professionell, fachlich kompetent und sicher auf. Sie beherrschen den Prozess der Softwareentwicklung von Anfang bis zum Ende und gliedern sich sehr gut in bestehende Organisationsstrukturen ein. Neben dem tiefen technischen Wissen bringen sie auch Soft-Skills mit, die in der Abwicklung von Software-Projekten unerlässlich sind. Durch diese Art der Zusammenarbeit wurden die Projekte zur unserer vollsten Zufriedenheit abgeschlossen.',
    },
  },
  team: {
    title: 'Wer wir sind',
    showDetail: 'Alles über {{name}}',
    showOverview: 'Zurück zum Team',
    showCV: 'Gesamten Lebenslauf ansehen',
    hideDetail: 'Zuklappen',
    experiences: 'Erfahrungen',
    paul: {
      teaser: 'Die Softwareentwicklung begleitet mich seit mittlerweile über einem Jahrzehnt und es wird mir immer wieder aufs Neue bewusst, dass ich in dieser Disziplin meine Leidenschaft gefunden habe.',
      detail: `Die Leidenschaft zur Entwicklung habe ich auch in Wissen verewigt, indem ich sowohl das Bachelorstudium “Softwareentwicklung und Wirtschaft” als auch Masterstudium “Software Engineering and Management” auf der TU Graz in Mindeststudienzeit mit Auszeichnung abgeschlossen habe.

            Mein Steckenpferd ist die Web-Entwicklung. Hier konnte ich bereits vielzählige Jahre an Berufserfahrung sammeln und bin mit allen gängigen Frontend Frameworks (React, Angular und Vue) in Berührung gekommen. Auch am Backend durfte ich bereits an einigen Spring Boot Applikationen arbeiten und habe sowohl mit PostgreSQL als auch MySQL zahlreiches an Wissen zur Datenbankadministration gewonnen.

            Während dem Entstehen der Anwendungen stehen für mich immer zwei Punkte im Fokus: Die Applikation muss dem Endnutzer gefallen und der Kunde muss seine Ziele damit erreichen. Denn nur eine Anwendung, die man gerne verwendet, ist erfolgreich umgesetzt.`,
    },
    philipp: {
      teaser: 'Für mich ist Software zu entwickeln nicht nur Beruf sondern vielmehr Berufung. Dabei fasziniert mich nicht nur die Technik, sondern auch die organisatorischen und menschlichen Themen, die ebenso zur Softwareentwicklung dazu gehören.',
      detail: `Nach meinem Abschluss der HTBLA Kaindorf a.d. Sulm mit Auszeichnung, wollte ich mein dort erworbenes Wissen endlich praktisch anwenden und bin daher direkt ins Berufsleben eingestiegen. Während meines beruflichen Werdegangs, konnte ich Erfahrungen in unterschiedlichen Unternehmen mit verschiedenen fachlichen und technischen Schwerpunkten und in diversen Rollen sammeln und mich so immer weiterentwickeln. Die Highlights daraus sind:<ul>
  <li>Intensive Erfahrungen mit Entwicklung in Legacy Systemen bis hin zu modernen Applikationen mit neuesten Vorgehensweisen</li>
  <li>Aufbau und die Optimierung von Development-Pipelines von Build Tools über diverse CI/CD Systeme bis hin zu Infrastructure as Code</li>
  <li>Inhaltliche Leitung eines Operations-Teams und mehrjährige Hands-On Betrieb Erfahrung in Startups und KMUs</li>
  <li>Ausfüllen von Doppelrollen als Entwickler, Operator und Scrum-Master oder durch das Übernehmen von Product-Owner Tätigkeiten</li>
  <li>Mehrere Jahre Spiele- und Framework-Entwicklung mit Unity3d und C#</li>
</ul>Obwohl mein Fokus bisher eher in Backend-, Betriebs- oder Organisatorischen-Tätigkeiten lag, habe ich mich auch immer wieder der Frontend Entwicklung gewidmet und möchte mein Wissen in diesem Bereich ebenso weiter stärken, um Kunden jederzeit möglichst ganzheitlich Unterstützen zu können. Neben den Themen, die mich im Arbeitsalltag begleiten, erweitere ich auch gerne mein Wissen durch private Projekte, den Besuch von Stammtischen/Meetups und das Lesen von Fachlektüre um jederzeit auf dem neuesten Stand der Technik und Vorgehensweisen zu sein.`,
    },
  },
  contact: {
    title: 'Kontakt',
    text: `Wir sind dein Partner, der mit dir
    dein Softwareprojekt verwirklicht.
    Wir freuen uns über deine Anfrage!`,
    imprint: 'Impressum',
    privacyPolicy: 'Datenschutzerklärung',
    generalTermsAndConditions: 'AGB',
  },
  imprint: {
    header: 'Impressum',
    contactData: 'Kontakdaten',
    name: {
      header: 'Firmenwortlaut',
      content: 'Devaholics Software & Consulting GmbH',
    },
    managingDirectors: {
      header: 'Geschäftsführer',
      content: 'Paul Ganster, Philipp Sommersguter',
    },
    companyObject: {
      header: 'Unternehmensgegenstand',
      content: 'Dienstleistungen in der Datenverarbeitung und Informationstechnik',
    },
    address: {
      header: 'Firmensitz',
      content: `Neubaugasse 24/1
      8020 Graz
      Österreich`,
    },
    vat: {
      header: 'UID-Nummer',
      content: 'ATU77207005',
    },
    registrationNumber: {
      header: 'Firmenbuchnummer',
      content: '562376 z, Landesgericht für ZRS Graz',
    },
    registrationCourt: {
      header: 'Firmenbuchgericht',
      content: 'Graz',
    },
    chamberOfCommerceNotice: {
      header: 'Kammerzugehörigkeit',
      content: 'Mitglied der Wirtschaftskammer Steiermark',
    },
    photoMaterial: {
      header: 'Bildmaterial',
      content: 'Brand Images e.U.',
    },
  },
  privacyPolicy: {
    header: 'Datenschutzerklärung',
    safety: {
      header: 'Datenschutz',
      content: 'Wir verarbeiten deine Daten ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir dich über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.',
    },
    dataUsageContact: {
      header: 'Kontakt über E-Mail',
      content: 'Wenn du per E-Mail Kontakt mit uns aufnimmst, werden deine angegebenen Daten zur Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne deine Einwilligung weiter.',
    },
    rights: {
      header: 'Deine Rechte',
      content: 'Dir stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn du glaubst, dass die Verarbeitung deiner Daten gegen das Datenschutzrecht verstößt oder deine datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, kannst du dich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.',
    },
    enquiries: {
      header: 'Datenauskünfte, Infos und Beschwerden',
    },
  },
};
export default de;
