import React from 'react';
import { Trans } from 'react-i18next';
import Page from '../../components/mobile/Page';
import CodeLine from '../../components/common/CodeLine';
import './Customers.scss';
import CustomerCarousel from './CustomerCarousel';
import StickyTitle from '../../contact/mobile/StickyTitle';

const marginTop = 192;

const background = (
  <>
    <CodeLine
      color="codeline-grey-transparentized"
      top={26 - marginTop}
      right={0}
      width={62}
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={26 - marginTop}
      right={78}
      leftRound
      rightRound
    />
    <CodeLine
      color="codeline-grey"
      top={92 - marginTop}
      left={0}
      width={72}
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={92 - marginTop}
      left={88}
      width={115}
      leftRound
      rightRound
    />
  </>
);

export default function Customers() {
  return (
    <Page background={background} lightGreyBackground>
      <div className="Customers" style={{ marginTop }}>
        <StickyTitle>
          <Trans i18nKey="customers.title" />
        </StickyTitle>
        <div className="Customers-references">
          <CustomerCarousel />
        </div>
      </div>
    </Page>
  );
}
