import React from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Pagination } from 'swiper';
import { PaginationOptions } from 'swiper/types';
import customerReferences, { CustomerReference } from '../common/CustomerReferences';
import { ReactComponent as ArrowLeft } from '../../assets/icons/arrow-grey-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-grey-right.svg';
import './CustomerCarousel.scss';
import 'swiper/css';
import PersonWithCompany from '../common/PersonWithCompany';
import CustomerReferenceText from '../common/CustomerReferenceText';

function CarouselItem({ customerReference }: { customerReference: CustomerReference }) {
  const swiper = useSwiper();
  const {
    logo, url,
  } = customerReference;
  return (
    <article>
      <div className="CustomerCarousel-header">
        <button type="button" onClick={() => swiper.slidePrev()}>
          <ArrowLeft />
        </button>
        <div className="CustomerCarousel-header-logo">
          <a href={url} target="_blank" rel="noreferrer">
            {logo}
          </a>
        </div>
        <button type="button" onClick={() => swiper.slideNext()}>
          <ArrowRight />
        </button>
      </div>
      <p className="CustomerCarousel-text">
        <CustomerReferenceText customerReference={customerReference} />
      </p>
      <PersonWithCompany customerReference={customerReference} />
    </article>
  );
}

export default function CustomerCarousel() {
  const pagination: PaginationOptions = {
    clickable: true,
    renderBullet: (_: number, className: string) => `
        <button
          type="button"
          class="${className}"
        />
        `,
  };

  return (
    <div className="CustomerCarousel">
      <Swiper
        loop
        slidesPerView={1}
        spaceBetween={48}
        pagination={pagination}
        modules={[Pagination]}
      >
        {
          customerReferences.map((customer) => (
            <SwiperSlide key={customer.textI18nKey}>
              <CarouselItem
                customerReference={customer}
              />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </div>
  );
}
