import React from 'react';
import { CustomerReference } from './CustomerReferences';
import './PersonWithCompany.scss';
import Link from '../../components/common/Link';

export default function PersonWithCompany(props: { customerReference: CustomerReference }) {
  const {
    customerReference: {
      person, url, company,
    },
  } = props;
  return (
    <div className="PersonWithCompany">
      <div className="PersonWithCompany-dash">{'\u2014'}</div>
      <div className="PersonWithCompany-person">{person}</div>
      <div className="PersonWithCompany-link">
        <Link href={url}>
          {company}
        </Link>
      </div>
    </div>
  );
}
