import React from 'react';
import './Services.scss';
import { Trans } from 'react-i18next';
import Page from '../../components/desktop/Page';
import CodeLine from '../../components/common/CodeLine';
import { ProjectsBackground, StartBackground } from '../../components/desktop/Backgrounds';
import ServiceAreas from '../common/ServiceAreas';

const background = (
  <>
    <StartBackground shifted />
    <ProjectsBackground shifted />
    <CodeLine
      color="purple"
      bottom={75}
      left={0}
      width={150}
      rightRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={197}
      right={0}
      width={241}
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={300}
      right={75}
      width={311}
      leftRound
      rightRound
    />
  </>
);

export default function Services() {
  return (
    <Page index={1} background={background}>
      <div className="Services">
        <h1>
          <Trans i18nKey="menu.services.displayText" />
        </h1>
        <p className="Services-introduction">
          <Trans i18nKey="services.teaser" />&nbsp;
          <Trans i18nKey="services.introduction" />&nbsp;
          <Trans i18nKey="services.servicesPresentation" />:
        </p>
        <ServiceAreas />
      </div>
    </Page>
  );
}
