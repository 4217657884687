import React from 'react';
import { Trans } from 'react-i18next';
import Page from '../../components/mobile/Page';
import ProjectsCarousel from '../common/ProjectsCarousel';
import './Projects.scss';
import CodeLine from '../../components/common/CodeLine';
import StickyTitle from '../../contact/mobile/StickyTitle';

const background = (
  <>
    <CodeLine
      color="codeline-grey-transparentized"
      top={26}
      right={0}
      width={62}
      leftRound
    />
    <CodeLine
      color="codeline-grey-transparentized"
      top={26}
      right={78}
      leftRound
      rightRound
    />
  </>
);
export default function Projects() {
  return (
    <Page background={background}>
      <div className="Projects">
        <StickyTitle>
          <Trans i18nKey="projects.title" />
        </StickyTitle>
        <ProjectsCarousel />
      </div>
    </Page>
  );
}
