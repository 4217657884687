import React from 'react';
import './Page.scss';
import Next from './Next';

type Props = {
  index: number,
  children: React.ReactNode,
  background: React.ReactNode
};

export default function Page({ index, children, background }: Props) {
  return (
    <div className="Page">
      <Next currentPage={index} />
      <section className="Page-content">
        {children}
      </section>
      <div className="Page-background">
        {background}
      </div>
    </div>
  );
}
