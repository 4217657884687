import React from 'react';
import { Trans } from 'react-i18next';
import { TFuncKey } from 'i18next';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import './SlidingContentOverlay.scss';
import useTogglePageScroll from '../../components/mobile/UseTogglePageScroll';

type Props = {
  visible: boolean;
  onHide: () => void,
  headerI18nKey: TFuncKey,
  children: React.ReactNode,
};

export default function SlidingContentOverlay(props: Props) {
  const {
    visible, onHide, headerI18nKey, children,
  } = props;
  useTogglePageScroll(!visible);

  return (
    <section className={`SlidingContentOverlay${visible ? '' : ' hidden'}`}>
      <div className="SlidingContentOverlay-header">
        <h1><Trans i18nKey={headerI18nKey} /></h1>
        <button type="button" onClick={onHide}>
          <CloseIcon />
        </button>
      </div>
      {children}
    </section>
  );
}
