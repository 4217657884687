import React from 'react';
import { Trans } from 'react-i18next';
import Page from '../../components/mobile/Page';
import './Contact.scss';
import CodeLine from '../../components/common/CodeLine';
import ContactMailButton from '../common/ContactMailButton';
import StickyTitle from './StickyTitle';

const background = (
  <>
    <CodeLine
      color="codeline-grey"
      top={26}
      right={0}
      width={90}
      leftRound
    />
    <CodeLine
      color="codeline-grey"
      top={26}
      right={106}
      leftRound
      rightRound
    />
  </>
);
export default function Contact() {
  return (
    <Page background={background}>
      <div className="Contact">
        <StickyTitle>
          <Trans i18nKey="contact.title" />
        </StickyTitle>
        <p className="Contact-text">
          <Trans i18nKey="contact.text" />
        </p>
        <ContactMailButton />
      </div>
    </Page>
  );
}
