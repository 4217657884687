import React from 'react';
import { Trans } from 'react-i18next';
import { menuItemsDisplay } from '../../constants';
import { ReactComponent as ArrowRight } from '../../assets/icons/arrow-right-big.svg';
import './Next.scss';
import goToPage from '../common/GoToPage';

type Props = {
  currentPage: number,
};

export default function Next({ currentPage }: Props) {
  const nextMenuItem = menuItemsDisplay[currentPage];

  if (nextMenuItem === undefined) return null;

  return (
    <button
      className="Next"
      onClick={() => goToPage(currentPage + 1)}
      type="button"
    >
      <span className="Next-text">
        <Trans i18nKey={nextMenuItem} />
      </span>
      <ArrowRight className="Next-icon" />
    </button>
  );
}
