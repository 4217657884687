import React from 'react';
import { ReactComponent as EmailIcon } from '../../assets/icons/email.svg';
import MailAddress from '../../components/desktop/MailAddress';
import MailToAction from './MailToAction';
import './ContactMailButton.scss';

export default function ContactMailButton() {
  return (
    <MailToAction className="ContactMailButton">
      <EmailIcon className="ContactMailButton-icon" />
      <MailAddress />
    </MailToAction>
  );
}
